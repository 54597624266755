import React from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionPage, Container } from "../components/Section"
import { Nodata, NodataDesc, NodataMedia } from "../components/Nodata"
import SuccessCheck from "../components/Icons/SuccessCheck"

const Thanks = ({ data, location }) => (
  <Layout location={location}>
	<Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
    <SEO title="thanks" />
	<SectionPage
          ept="160px"
          epb="60px"
          xpt="160px"
          xpb="40px"
          pt="100px"
          pb="30px"
          bg="#EBF8FF"
        >
			<Container>
				<Nodata>
					<NodataMedia><SuccessCheck /></NodataMedia>
					<NodataDesc mb="30px" color="#062C44">
						<p>Thank you for connecting with us <br/> Our Representative will get back to you with the requested building quote soon...</p>
					</NodataDesc>
				</Nodata>
			</Container>
		</SectionPage>
  </Layout>
)

export default Thanks
