import React from 'react'
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from '../components/layout'
import CallToAction from "../components/CallToAction"
import { SectionPage, BreadCrumb, MarkdownContent, SectionPageTitle, Container } from "../components/Section"
import generateHTML from '../utils/generateHTML'

class FullContentTemplate extends React.Component {
	render() {
		const pageData = this.props.data.contentfulContentPage
		return(
			<Layout location={this.props.location}>
				<SEO title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
				<SectionPage
				ept="160px"
				epb="80px"
				xpt="160px"
				xpb="40px"
				pt="100px"
				pb="30px"
				bg="#fff">
					<Container>
						<BreadCrumb className='static'>Home / <span>{pageData.title}</span></BreadCrumb>
						<SectionPageTitle>{pageData.title}</SectionPageTitle>						
						<MarkdownContent dangerouslySetInnerHTML={{__html: generateHTML(pageData.content.content)}} />
					</Container>
				</SectionPage>
				<CallToAction />
			</Layout>
		)
	}
}

export default  FullContentTemplate

export const pageQuery = graphql`
  query fullContentQuery($id: String!) {
  	contentfulContentPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
	      metaDescription
	    }
      content {
        content
      }
	  }
  }
`