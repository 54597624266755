import React from "react"
import {
  SectionPage,
  SectionHeading,
  Container,
  SectionDescription
} from "../../components/Section"
import Map from "../../components/Map"

const MapSection = ({ data }) => {
  return (
    <SectionPage
      overflow="hidden"
      ept="80px"
      xpt="60px"
      pt="40px"
      pb="0"
      bg="#1E4156"
    >
      {/* <SectionHeading textAlign="center" color='#fff'>{data.data.title}</SectionHeading> */}
      {/* <SectionDescription color='#fff'><p>Nationwide services in almost all 50 states</p></SectionDescription> */}
      {/* <Description dangerouslySetInnerHTML={{__html: data.data.description.childMarkdownRemark.html}} /> */}  
      <Container>
        <SectionHeading textAlign="center" color="#fff" mb="20px">States We Serve In the U.S.</SectionHeading>
        <SectionDescription color='#fff' maxWidth="980px"><p>We proudly serve every state in the continental United States. Contact us at <a href="tel:8777541818">(877) 754-1818</a> for personalized assistance, and let us help you find the perfect structure for your property!</p></SectionDescription>
      </Container>
      <Map
        stateName={data.stateName}
        mapImage={data.mapImage}
        cities={data.cities}
      />
    </SectionPage>
  )
}

export default MapSection
