import React from 'react'
import styled from 'styled-components'
import BBB from "../../images/bbb.png"

const DesignCard = styled.div`
	background: #fff;
	border: 1px solid #98DFFF;
	box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
	border-radius: 10px;	
	margin-bottom:15px;
`

const DesignBody = styled.div`
	text-align: center;
	padding: 20px 15px;
	@media (min-width: 992px) {
		padding: 30px 20px;
	}
	@media (min-width: 1200px) {
		padding: 30px;
	}
`
const BusinessBlock = styled.div`
	display:flex;
	flex-wrap:wrap;
	justify-content: center;
	margin:0 -2px;
`

const BBBPan = styled.div `
	display:flex;
	flex-wrap:nowrap;
	border-radius: 10px;
	background:#fff;
	box-shadow: 0px 4px 12px rgba(0,0,0,0.2);
	overflow:hidden;
	margin:0 2px;
	.icon{
		display:flex;
		align-items:center;
		padding:8px;
	}
	& .text {
		padding: 8px 10px;
		text-transform: uppercase;
		background: #035A78;
		color: #fff;
		font-family: Chakra Petch;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		max-width:130px;
		width:100%;
		margin:0 auto;
		@media (min-width: 992px) {
			font-size: 18px;
			line-height: 24px;
		}
	}
`
const Rating= styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	margin:0 2px;
	background: #035A78;
	color: #fff;
	text-align: center;
	box-shadow: 0px 4px 12px rgba(0,0,0,0.2);
	border-radius: 10px;
	padding: 8px 10px;
	& strong {
		display:block;
		font-family: Chakra Petch;		
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		@media (min-width: 992px) {
			font-size: 18px;
			line-height: 22px;
		}
	}
	& span {
		font-size: 14px;
		line-height: 20px;
		color: #fff;
		margin: 0;
	}
`

const AccreditedBusiness = () => {
	return(		
		<DesignCard>
			<DesignBody>
				<a href="https://www.bbb.org/us/fl/lake-city/profile/portable-buildings/buildings-more-0403-1124279" target="_blank">
					<BusinessBlock>
						<BBBPan>
							<span className='icon'><img src={BBB} alt="business" /></span>
							<span className='text'>Accredited Business</span>
						</BBBPan>
						<Rating>
							<div><strong>A+</strong><span>RATING</span></div>
						</Rating>
					</BusinessBlock>
				</a>
			</DesignBody>
		</DesignCard>
	)
}

export default AccreditedBusiness