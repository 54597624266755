import React, { Component } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Slider from "react-slick"
import ArrowIcon from "../Icons/ArrowIcon"
import LeftArrowIcon from "../Icons/LeftArrowIcon"
import PriceTip from "../Button/PriceTip"
import BestPriceTip from "../../images/best.png"
import AffordablePriceTip from "../../images/affordable.png"
import BudgetPriceTip from "../../images/budget.png"

const Wrapper = styled.div`
  position: relative;
  margin: auto;
  width: 600px;
  & .gatsby-image-wrapper {
    height: 400px;
    border-radius: 10px;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 610px) {
    width: 420px;
    & .gatsby-image-wrapper {
      height: 300px;
    }
  }
  @media (max-width: 440px) {
    width: 350px;
    & .gatsby-image-wrapper {
      height: 250px;
    }
  }
  @media (max-width: 370px) {
    width: 320px;
    & .gatsby-image-wrapper {
      height: 250px;
    }
  }
`
const RightArrow = styled.div`
  position: absolute;
  top: 200px;
  right: -30px;
  width: 54px;
  height: 54px;
  padding: 12px 5px;
  border-radius: 60px;
  background: #1e4156;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all 0.3s ease;
      transform: translateX(5px);
    }
  }
  @media (max-width: 480px) {
    top: 125px;
    right: -10px;
  }
`
const LeftArrow = styled.div`
  position: absolute;
  top: 200px;
  left: -30px;
  width: 54px;
  height: 54px;
  padding: 12px 5px;
  border-radius: 60px;
  background: #1e4156;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all 0.3s ease;
      transform: translateX(-5px);
    }
  }
  @media (max-width: 480px) {
    top: 125px;
    left: -10px;
  }
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

export default class ProductImageCarousel extends Component {
  constructor(props) {
    super(props)
    this.renderSliderContent = this.renderSliderContent.bind(this)
    this.priceTip = this.priceTip.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  renderSliderContent() {
    const { product } = this.props
    return product.map((item, i) => {
      return (
        <div key={i}>
          <span>{this.priceTip(item)}</span>
          {item.node.image ? (
            <Img
              fluid={item.node.image.fluid}
              alt={`image for ${item.node.title}`}
            />
          ) : (
            <img
              src={item.node.imageSrc}
              alt={item.node.title}
            />
          )}
        </div>
      )
    })
  }
  priceTip(data) {
    if (data.node.productRoofType.name === "Vertical Roof") {
      return (
        <span>
          <PriceTip
            text="Best"
            bgImg={BestPriceTip}
            width="75px"
            mb="-70px"
            mt="-32px"
          />
        </span>
      )
    } else if (data.node.productRoofType.name === "A-Frame Roof") {
      return (
        <span>
          <PriceTip
            text="Affordable"
            bgImg={AffordablePriceTip}
            width="110px"
            pr="7px"
            mb="-70px"
            mt="-32px"
          />
        </span>
      )
    } else {
      return (
        <span>
          <PriceTip
            text="Budget"
            bgImg={BudgetPriceTip}
            width="90px"
            pr="5px"
            mb="-70px"
            mt="-32px"
          />
        </span>
      )
    }
  }
  render() {
    const settings = {
      autoPlay: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }
    return (
      <Wrapper>
        <div style={{ textAlign: "center" }}>
          <LeftArrow onClick={this.previous}>
            <LeftArrowIcon fill="white" />
          </LeftArrow>
          <RightArrow onClick={this.next}>
            <ArrowIcon fill="white" />
          </RightArrow>
        </div>
        <Slider {...settings} ref={c => (this.slider = c)}>
          {this.renderSliderContent()}
        </Slider>
      </Wrapper>
    )
  }
}
