import React from "react"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CallToAction from "../components/CallToAction"
import {
  BreadCrumb,
  MainPanel,
  LeftPanel,
  RightPanel,
  MarkdownContent,
  Container,
  SectionPage,
  SectionPageTitle,
  MarkdownImage,
} from "../components/Section"
import { SidebarSticky } from "../components/Sidebar"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import RightForm from "../components/RightForm"
import generateHTML from "../utils/generateHTML"

class BuyingGuideTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulContentPage
    if (pageData.rightFormTitle !== null) {
      return (
        <Layout location={this.props.location}>
          <SEO
            title={pageData.metaTitle}
            description={pageData.metaDescription.metaDescription}
          />
          <SectionPage
            ept="160px"
            epb="60px"
            xpt="160px"
            xpb="40px"
            pt="100px"
            pb="30px"
            bg="#fff"
          >
            <Container>
              <BreadCrumb className="static">
                <Link to="/">Home</Link> /{" "}
                <Link to="/buying-guide/">Buying Guide</Link> /{" "}
                <span>{pageData.title}</span>
              </BreadCrumb>
              <MainPanel>
                <LeftPanel>
                  <SectionPageTitle>{pageData.title}</SectionPageTitle>
                  <MarkdownImage>
                    <Img
                      fluid={pageData.image.fluid}
                      alt={`image for ${pageData.title}`}
                    />
                  </MarkdownImage>
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: generateHTML(pageData.content.content),
                    }}
                  />
                </LeftPanel>
                <RightPanel>
                  <SidebarSticky className="isSticky">
                    <RightForm
                      title={pageData.rightFormTitle}
                      location={this.props.location}
                      formName={`${pageData.title}-Contact Form`}
                    />
                    <DesignPlan />
                    <AccreditedBusiness />
                  </SidebarSticky>
                </RightPanel>
              </MainPanel>
            </Container>
          </SectionPage>
          <CallToAction />
        </Layout>
      )
    } else {
      return (
        <Layout location={this.props.location}>
          <SectionPage
            ept="160px"
            epb="60px"
            xpt="160px"
            xpb="40px"
            pt="100px"
            pb="30px"
            bg="#fff"
          >
            <Container>
              <BreadCrumb className="static">
                Home / Buying Guide / <span>{pageData.title}</span>
              </BreadCrumb>
              <SectionPageTitle>{pageData.title}</SectionPageTitle>
              <Img
                fluid={pageData.image.fluid}
                alt={`image for ${pageData.title}`}
              />
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(pageData.content.content),
                }}
              />
            </Container>
          </SectionPage>
          <CallToAction />
        </Layout>
      )
    }
  }
}

export default BuyingGuideTemplate

export const pageQuery = graphql`
  query buyingGuideQuery($id: String!) {
    contentfulContentPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      content {
        content
      }
      createdAt(fromNow: true)
      image {
        fluid(maxWidth: 1000) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      rightFormTitle
    }
  }
`
