import React from "react"
import {
  Container,
  SectionPage,
  SectionDescription,
  SectionHeading,
} from "../../components/Section"
import IntroAccordian from "../../components/IntroAccordian"

const IntroSection = ({ data, imageData }) => {
  return (
    <SectionPage
      ept="40px"
      epb="0"
      xpt="40px"
      xpb="0"
      pt="40px"
      pb="0"
      bg="#fff"
    >
      <Container>
        <SectionHeading textAlign="center">{data.title}</SectionHeading>
        <SectionDescription>
          <p>{data.description.description}</p>
        </SectionDescription>
      </Container>
      <IntroAccordian data={data.features} imageData={imageData} />
    </SectionPage>
  )
}

export default IntroSection
