import styled from 'styled-components'

export const DropWrapp = styled.div`
	cursor: pointer;
	padding: 15px 30px;
	border: 1px solid #98DFFF;
	border-radius: 5px;
	box-shadow: ${props=>(props.isVisible ? "0 15px 45px rgba(152, 223, 255, 0.2)" : "none")};
	margin-bottom:15px;
	
`
export const DropButton = styled.div`
	font-size: 24px;
	text-align: left;
	color: #484D4E;
	margin-top: 0;
	width: 100%;
	display: flex;
	line-height: 30px;
	align-items: center;
	@media(max-width: 480px) {
		font-size: 20px;
	}
`
export const DropButtonText = styled.div`
	width: calc(100% - 30px);
	display: inline-block;
	color: #062C44;
	font-family: Chakra Petch;
	font-weight: 700; 
	line-height: 29px;
`
export const DropButtonImg = styled.div`
	width: 30px;
	display: inline-block;
	& svg {
		float: right;
		transform: ${props=>(props.isVisible ? "rotate(45deg)" : "rotate(90deg)")};
		transition: all .3s ease;
	}
`
export const FadeContent = styled.div`
	display: ${props=>(props.isVisible ? "block" : "none")};
	border-top: 1px solid #98DFFF;
	margin-top: 30px;
	padding-top: 20px;
	padding-bottom: 30px;
	@media(max-width: 480px) {
		margin-top: 15px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
`