import React, { Fragment, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import SearchIcon from "../Icons/SearchIcon"
import { navigate } from "gatsby"
import { DropSearch, DropSearchItem } from "../Section"
import { useSearch } from "../../utils/useSearch"
import { getProductUrl } from "../../utils/product"

const SearchForm = styled.div`
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  form {
    position: relative;
    margin-bottom: 0;
    input {
      width: 100%;
      border: 1px solid rgb(220, 231, 239);
      border-radius: 6px;
      padding: 8px 40px 8px 5px;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }

    button {
      position: absolute;
      border: 0px;
      outline: 0px;
      background: none;
      padding: 0;
      right: 0;
      top: 0;
      z-index: 1;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      svg {
        fill: gray;
        width: 16px;
        height: 16px;
      }
    }
  }
`

const HeaderSearchBox = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const targetRef = useRef(null)
  const searchInputRef = useRef(null)
  const [searchKey, setSearchKey] = useState("")
  const results = useSearch(searchKey, "", true)

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleClickOutside = event => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      if (event.target.id !== searchInputRef.current.id) {
        setShowDropdown(false)
      }
    }
  }

  const handleChange = e => {
    e.preventDefault()
    setSearchKey(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    navigate("/search", { state: { searchKey: searchKey } })
  }

  const getLinkUrl = item => {
    if (item.type === "tag") {
      navigate("/shop-metal-buildings/", {
        state: { tag: item.title },
      })
    } else {
      const url = getProductUrl(item)
      navigate(`${url}/`)
    }
  }

  const getSearchItemTitle = item => {
    if (item.type === "tag") return item.title
    if (searchKey === item.skuNumber.toString()) return item.skuNumber
    return item.title
  }

  return (
    <Fragment>
      <SearchForm className="search-form">
        <form onSubmit={handleSubmit}>
          <input
            ref={searchInputRef}
            className="form-control"
            type="text"
            placeholder="Search..."
            id="search"
            name="search"
            autoComplete="off"
            onFocus={() => setShowDropdown(true)}
            onChange={e => {
              handleChange(e)
              setShowDropdown(true)
            }}
            onKeyDown={event => {
              if (event.key === "Enter") {
                setShowDropdown(false)
              }
            }}
          />
          <button type="submit" aria-label="Search">
            <SearchIcon />
          </button>
        </form>
      </SearchForm>
      {showDropdown && (
        <DropSearch className="drop-search" ref={targetRef}>
          {results.map((rItem, index) => (
            <DropSearchItem
              className="drop-search-item"
              key={index}
              onClick={() => getLinkUrl(rItem)}
            >
              {getSearchItemTitle(rItem)}
            </DropSearchItem>
          ))}
        </DropSearch>
      )}
    </Fragment>
  )
}

export default HeaderSearchBox
