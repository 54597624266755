import React from "react"
import Map from "../../components/Map"
import { SectionPage, Container, SectionPageTitle,SectionDescription } from "../../components/Section"

const MapSection = ({ stateName, mapImage, cities }) => {
  return (
    <SectionPage
      ept="80px"
      epb="80px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
      bg="#1E4156"
      >
      <Container>
        <SectionPageTitle textAlign="center" color="#fff" mb="20px">States We Serve In the U.S.</SectionPageTitle>
        <SectionDescription maxWidth="980px" color='#fff'><p>We proudly serve every state in the continental United States. Contact us at <a href="tel:8777541818">(877) 754-1818</a> for personalized assistance, and let us help you find the perfect structure for your property!</p></SectionDescription>
      </Container>
      <Map stateName={stateName} mapImage={mapImage} cities={cities} />
    </SectionPage>
  )
}

export default MapSection
