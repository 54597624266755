import React, { useEffect, useState } from "react"
import {
  Filter,
  ListBadge,
  PriceBadge,
  PriceItem,
  PriceRange,
  TopBar,
  TopLeftBar,
  TopMiddleBar,
  TopRightBar,
} from "./styles"
import {
  LeftPanelGrid,
  MainPanelGrid,
  RightPanelGrid,
  SectionPage,
} from "../../../components/Section"
import {
  ListFormGroup,
  ListItemForm,
  SidbarStickyFooter,
  SidebarCard,
  SidebarCardBody,
  SidebarCardHeader,
  SidebarCardTitle,
  SidebarHeaderSticky,
  SidebarItemToolbar,
  SidebarSticky,
  SidebarStickyAction,
  SidebarStickyBody,
  SidebarStickyClose,
  SidebarStickyTitle,
  SidebarStickyToolbar,
} from "../../../components/SidebarSticky"
import PageNav from "../../../components/PageNav"
import {
  Nodata,
  NodataDesc,
  NodataMedia,
  NodataTitle,
} from "../../../components/Nodata"
import { StaticImage } from "gatsby-plugin-image"
import ProductCardList from "../../../components/ProductCardList"
import FilterIcon from "../../../components/Icons/FilterIcon"
import PrimaryButton from "../../../components/Button/PrimaryButton"
import CloseIcon from "../../../components/Icons/CloseIcon"
import PrimaryLinkButton from "../../../components/Button/PrimaryLinkButton"
import Badge from "../../../components/Badge"
import { getCommonItems } from "../../../utils/string"
import DropArrowIcon from "../../../components/Icons/DropArrowIcon"
import PriceDropdown from "../../../components/PriceDropdown"

const defaultFilterCondition = {
  categories: [],
  roofTypes: [],
  priceRanges: [],
  tags: [],
  sortPrice: "topSelling",
}

const totalPriceRanges = [
  { label: "Under $2000", min: 0, max: 2000 },
  { label: "$2000 - $5000", min: 2000, max: 5000 },
  { label: "$5000 - $10000", min: 5000, max: 10000 },
  { label: "$10000 - $20000", min: 10000, max: 20000 },
  { label: "$20000 - $40000", min: 20000, max: 40000 },
  { label: "$40000 & Above", min: 40000, max: 10000000 },
]

const Products = ({
  location,
  products,
  productCategories,
  productRoofTypes,
}) => {
  const [loading, setLoading] = useState(true)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [filterCondition, setFilterCondition] = useState(defaultFilterCondition)
  const [totalTags, setTotalTags] = useState([])
  const [offsetTop, setOffsetTop] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(16)
  const [showSticky, setShowSticky] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  useEffect(() => {
    const getContentTop = () => {
      const el = document.getElementById("main-content")
      const top = el.getBoundingClientRect().top
      setOffsetTop(top + window.pageYOffset)
    }

    document.addEventListener("resize", getContentTop)
    getContentTop()

    let tTags = []
    products.forEach(p => {
      if (p.node.tags) {
        tTags = [...tTags, ...p.node.tags]
      }
    })
    const distinctArray = [...new Set(tTags.map(tag => tag.trim()))]
    setTotalTags(distinctArray)
    applyFilter(filterCondition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state?.tag) {
      const condition = {
        ...filterCondition,
        tags: [location.state.tag.trim()],
      }
      setFilterCondition(condition)
      applyFilter(condition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    setPageNo(1)
  }, [pageSize, filteredProducts])

  const scrollToProductList = () => {
    const offset = 120
    if (window.pageYOffset > offsetTop - offset) {
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth",
      })
    }
  }

  const toggleProductCategory = pCategory => {
    const categories = filterCondition.categories
    const id = categories.indexOf(pCategory)
    const newCategories = [...categories]
    if (id >= 0) {
      newCategories.splice(id, 1)
    } else {
      newCategories.push(pCategory)
    }

    setFilterCondition({
      ...filterCondition,
      categories: newCategories,
    })
  }

  const toggleProductRoofType = pRoofType => {
    const roofTypes = filterCondition.roofTypes
    const id = roofTypes.indexOf(pRoofType)
    const newRfoofTypes = [...roofTypes]
    if (id >= 0) {
      newRfoofTypes.splice(id, 1)
    } else {
      newRfoofTypes.push(pRoofType)
    }

    setFilterCondition({
      ...filterCondition,
      roofTypes: newRfoofTypes,
    })
  }

  const togglePriceRange = pRange => {
    const priceRanges = filterCondition.priceRanges
    const id = priceRanges.findIndex(pr => pr.label === pRange.label)
    const newPriceRanges = [...priceRanges]
    if (id >= 0) {
      newPriceRanges.splice(id, 1)
    } else {
      newPriceRanges.push(pRange)
    }

    setFilterCondition({
      ...filterCondition,
      priceRanges: newPriceRanges,
    })
  }

  const toggleTags = tag => {
    const tags = filterCondition.tags
    const id = tags.indexOf(tag)
    const newTags = [...tags]
    if (id >= 0) {
      newTags.splice(id, 1)
    } else {
      newTags.push(tag)
    }

    setFilterCondition({
      ...filterCondition,
      tags: newTags,
    })
  }

  const applyFilter = condition => {
    if (!condition) return

    setLoading(true)

    const { categories, roofTypes, priceRanges, tags, sortPrice } = condition

    const ps = products.filter(p => {
      if (
        categories.length &&
        !categories.includes(p.node.productCategory.name)
      ) {
        return false
      }

      // roof types check
      if (
        roofTypes &&
        roofTypes.length &&
        !roofTypes.includes(p.node.productRoofType.name)
      ) {
        return false
      }

      // check price
      if (priceRanges && priceRanges.length) {
        let checking = false
        priceRanges.forEach(pr => {
          if (pr.min <= p.node.price && pr.max > p.node.price) {
            checking = true
          }
        })

        if (!checking) return false
      }

      // check tags
      if (tags && tags.length) {
        if (!p.node.tags || !p.node.tags.length) return false
        const commonTags = getCommonItems(tags, p.node.tags)
        if (!commonTags || !commonTags.length) return false
      }

      return true
    })

    // sorting products
    if (sortPrice === "topSelling") {
      ps.sort((a, b) => {
        if (a.node.shopOrder === null || a.node.shopOrder === undefined) {
          return b.node.shopOrder === null || b.node.shopOrder === undefined ? 0 : 1
        }
        if (b.node.shopOrder === null || b.node.shopOrder === undefined) {
          return -1
        }

        return a.node.shopOrder - b.node.shopOrder
      })
    } else {
      ps.sort((a, b) => a.node.price - b.node.price)
      if (sortPrice === "desc") ps.reverse()
    }

    setFilteredProducts([...ps])

    if (window.innerWidth < 992) {
      setShowSticky(true)
    }
    scrollToProductList()

    let count = 0
    if (categories && categories.length) count++
    if (roofTypes && roofTypes.length) count++
    if (priceRanges && priceRanges.length) count++
    if (tags && tags.length) count++
    setFilterCount(count)
    setLoading(false)
  }

  const clearFilter = () => {
    setFilterCondition({ ...defaultFilterCondition })
    applyFilter(defaultFilterCondition)
  }

  const toggleSticky = () => {
    setShowSticky(!showSticky)
  }

  if (typeof window !== `undefined`) {
    if (showSticky === true) {
      document.body.classList.remove("modal-open")
    } else if (window.innerWidth < 992) {
      document.body.classList.add("modal-open")
    }
  }

  const renderProductList = (data, location) => {
    if (data.length === 0) {
      return (
        <Nodata>
          <NodataMedia>
            <StaticImage src="../../../images/no-result.png" alt="no-result" />
          </NodataMedia>
          <NodataTitle className="h2 mb-15">No Result Found</NodataTitle>
          <NodataDesc mb="30px">
            <p>We couldn't find any results that match your search.</p>
          </NodataDesc>
        </Nodata>
      )
    } else {
      return <ProductCardList data={data} noLimit location={location} />
    }
  }

  const startIndex = (pageNo - 1) * pageSize
  const endIndex = startIndex + pageSize
  const pageProducts = filteredProducts.slice(startIndex, endIndex)

  return (
    <SectionPage
      ept="60px"
      epb="60px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
      bg="#F4FBFF"
    >
      <div className="container">
        <MainPanelGrid id="main-content">
          <LeftPanelGrid>
            <SidebarSticky
              className={
                showSticky
                  ? "sidebar-sticky isSticky"
                  : "sidebar-sticky isSticky show"
              }
            >
              <SidebarHeaderSticky className="sidebar-header-sticky">
                <SidebarStickyTitle>
                  <span className="icon">
                    <FilterIcon />
                  </span>
                  <span className="text">Filter By</span>
                </SidebarStickyTitle>
                <SidebarStickyAction>
                  <SidebarStickyClose onClick={toggleSticky}>
                    <CloseIcon />
                  </SidebarStickyClose>
                </SidebarStickyAction>
              </SidebarHeaderSticky>
              <SidebarStickyBody className="sidebar-body-sticky">
                <SidebarCard className="sidebar-card">
                  <SidebarCardHeader className="sidebar-header">
                    <SidebarCardTitle>All Metal Buildings</SidebarCardTitle>
                  </SidebarCardHeader>
                  <SidebarCardBody className="sidebar-body">
                    <ListFormGroup className="list-formgroup">
                      {productCategories.map((pc, i) => (
                        <ListItemForm className="list-item" key={i}>
                          <div className="form-check">
                            <input
                              id={pc.node.name}
                              className="form-check-input"
                              type="checkbox"
                              checked={filterCondition.categories.includes(
                                pc.node.name
                              )}
                              onChange={() =>
                                toggleProductCategory(pc.node.name)
                              }
                            />{" "}
                            <label
                              className="form-check-label"
                              htmlFor={pc.node.name}
                            >
                              {pc.node.name}
                            </label>
                          </div>
                        </ListItemForm>
                      ))}
                    </ListFormGroup>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard className="sidebar-card">
                  <SidebarCardHeader className="sidebar-header">
                    <SidebarCardTitle>All Roof Style</SidebarCardTitle>
                  </SidebarCardHeader>
                  <SidebarCardBody className="sidebar-body">
                    <ListFormGroup className="list-formgroup">
                      {productRoofTypes.map(prt => (
                        <ListItemForm className="list-item" key={prt.node.name}>
                          <div className="form-check">
                            <input
                              id={prt.node.name}
                              className="form-check-input"
                              type="checkbox"
                              checked={filterCondition.roofTypes.includes(
                                prt.node.name
                              )}
                              onChange={() =>
                                toggleProductRoofType(prt.node.name)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={prt.node.name}
                            >
                              {prt.node.name}
                            </label>
                          </div>
                        </ListItemForm>
                      ))}
                    </ListFormGroup>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard className="sidebar-card">
                  <SidebarCardHeader className="sidebar-header">
                    <SidebarCardTitle>Price Range</SidebarCardTitle>
                  </SidebarCardHeader>
                  <SidebarCardBody className="sidebar-body">
                    <PriceRange className="list-range">
                      {totalPriceRanges.map((pr, i) => {
                        const isActive = filterCondition.priceRanges
                          .map(item => item.label)
                          .includes(pr.label)

                        return (
                          <PriceItem className="list-item-range" key={pr.label}>
                            <PriceBadge
                              bg={isActive ? "#fff" : "none"}
                              borderColor={isActive ? "#FF3E00" : "#E0EEF6"}
                              color={isActive ? "#FF3E00" : "#062C44"}
                            >
                              <input
                                type="checkbox"
                                onChange={() => togglePriceRange(pr)}
                              />
                              {pr.label}
                            </PriceBadge>
                          </PriceItem>
                        )
                      })}
                    </PriceRange>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard className="sidebar-card">
                  <SidebarCardHeader className="sidebar-header">
                    <SidebarCardTitle>Most Searched Tags</SidebarCardTitle>
                  </SidebarCardHeader>
                  <SidebarCardBody className="sidebar-body">
                    <ListBadge>
                      {totalTags.map(tag => (
                        <Badge
                          key={tag}
                          size="sm"
                          badgeText={tag}
                          badgeColor={
                            filterCondition.tags.indexOf(tag) >= 0
                              ? "active"
                              : "default"
                          }
                          onClick={() => toggleTags(tag)}
                        />
                      ))}
                    </ListBadge>
                  </SidebarCardBody>
                </SidebarCard>
              </SidebarStickyBody>
              <SidbarStickyFooter className="sidebar-footer-sticky">
                <SidebarStickyToolbar className="sidebar-toolbar">
                  <SidebarItemToolbar className="sidebar-toolbar-item">
                    <button
                      type="button"
                      onClick={() => applyFilter(filterCondition)}
                      className="btn-sm"
                    >
                      <PrimaryButton text="Apply" size="sm" />
                    </button>
                  </SidebarItemToolbar>
                  <SidebarItemToolbar className="sidebar-toolbar-item">
                    <button
                      type="button"
                      className="btn-sm"
                      onClick={clearFilter}
                    >
                      <PrimaryLinkButton text="Clear All Filters" size="sm" />
                    </button>
                  </SidebarItemToolbar>
                </SidebarStickyToolbar>
              </SidbarStickyFooter>
            </SidebarSticky>
          </LeftPanelGrid>
          {!loading && (
            <RightPanelGrid>
              <TopBar>
                <TopLeftBar>
                  <Filter type="button" onClick={() => toggleSticky()}>
                    <span className="text">Filter ({filterCount})</span>
                    <span className="icon">
                      <DropArrowIcon />
                    </span>
                  </Filter>
                </TopLeftBar>
                <TopMiddleBar>
                  <PageNav
                    scrollToStart={scrollToProductList}
                    count={filteredProducts.length}
                    pageNo={pageNo}
                    changePageNo={setPageNo}
                    pageSize={pageSize}
                    changePageSize={setPageSize}
                  />
                </TopMiddleBar>
                {!!filteredProducts && filteredProducts.length !== 0 && (
                  <TopRightBar>
                    <PriceDropdown
                      dir={filterCondition.sortPrice}
                      onChange={dir => {
                        const condition = {
                          ...filterCondition,
                          sortPrice: dir,
                        }
                        setFilterCondition(condition)
                        applyFilter(condition)
                      }}
                    />
                  </TopRightBar>
                )}
              </TopBar>
              {renderProductList(pageProducts, location)}
              <PageNav
                scrollToStart={scrollToProductList}
                count={filteredProducts.length}
                pageNo={pageNo}
                changePageNo={setPageNo}
                pageSize={pageSize}
                changePageSize={setPageSize}
              />
            </RightPanelGrid>
          )}
        </MainPanelGrid>
      </div>
    </SectionPage>
  )
}

export default Products
