import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  BreadCrumb,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import LazyLaod from "react-lazyload"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import QuickContactForm from "../components/QuickContactForm"
import CardSection from "../sections/Home/CardSection"

class ProductCategoryTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulCityLanding
    const AllProducts = this.props.data.allContentfulProduct.edges.filter(
      edge =>
        edge.node.productCategory &&
        edge.node.productCategory.name &&
        edge.node.productRoofType &&
        edge.node.productRoofType.name
    )

    return (
      <Layout location={this.props.location}>
        <SEO
          title={`Metal Buildings ${pageData.cityName} - Prefab Steel Buildings And Kits For Sale In ${pageData.cityName}, ${pageData.state.abbreviation}`}
          description={`Metal Buildings ${pageData.cityName} - Discover extensive range of steel buildings, metal garages, RV carports, metal barns and kits in ${pageData.cityName}. Order your building now.`}
        />
        <HeroSection>
          <div className="container">
            <BreadCrumb className="static">
              <Link to="/">Home</Link> /{" "}
              <Link to={`${pageData.state.url}/`}>{pageData.state.stateName}</Link> /{" "}
              <span> {pageData.heroTitle}</span>
            </BreadCrumb>
            <HeroCover>
              <LeftHero>
                <HeroPageTitle>{pageData.heroTitle}</HeroPageTitle>
                <HeroDesc>
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                </HeroDesc>
              </LeftHero>
              <RightHero>
                <HeroFormWrap>
                  <HeroFormTitle>Get In Touch</HeroFormTitle>
                  <HeroFormBody>
                    <QuickContactForm
                      location={this.props.location}
                      formName={`${pageData.cityName} Product Contact Form`}
                    />
                  </HeroFormBody>
                </HeroFormWrap>
              </RightHero>
            </HeroCover>
          </div>
        </HeroSection>

        <LazyLaod>
          <CardSection
            data={{
              title: "Check Out Our Top-Selling Buildings",
              description: {},
            }}
            productData={AllProducts}
          />
        </LazyLaod>
        <CallToAction />
        <Section pt="90px" pb="90px">
          <div className="container">
            <LeftPannel>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: pageData.content.childMarkdownRemark.html,
                }}
              />
            </LeftPannel>
            <RightPannel>
              <DesignPlan />
              <AccreditedBusiness />
            </RightPannel>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default ProductCategoryTemplate

export const pageQuery = graphql`
  query cityLandigQuery($id: String!) {
    contentfulCityLanding(id: { eq: $id }) {
      cityName
      heroTitle
      state {
        abbreviation
        stateName
        url
      }
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcWebp
          srcSetWebp
          srcSet
        }
      }
      productListTitle
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
