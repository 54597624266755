import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import PrimaryButton from "../Button/PrimaryButton"
import { ExploreMore } from '../Section'

const DesignCard = styled.div`
	background: #fff;
	border: 1px solid #98DFFF;
	box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
	border-radius: 10px;	
	margin-bottom:15px;
`

const DesignBody = styled.div`
	text-align: center;
	padding: 20px 15px;
	@media (min-width: 992px) {
		padding: 30px 20px;
	}
	@media (min-width: 1200px) {
		padding: 30px;
	}
`
const DesignTitle = styled.h2`

`
const DesignThumb = styled.div`
	margin-bottom:15px;
`
const DesignPlan = () => {
	return(
		<DesignCard>
			<DesignBody>
				<DesignTitle>Design & Plan The Building</DesignTitle>
				<p>You can use 3D Design Tool to Customize & Visualize your Building.</p>
				<DesignThumb><StaticImage src="../../images/3d-house.png" alt="Design & Plan The Building" /></DesignThumb>
				<ExploreMore>
					<a href="https://probuiltsteel.sensei3d.com/" target="_blank" rel="noreferrer" className='btn-lg'><PrimaryButton text="3D Estimator" /></a>
				</ExploreMore>
			</DesignBody>
		</DesignCard>
	)
}

export default DesignPlan