import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  SectionDescription,
  SectionPage,
  SectionPageTitle,
} from "../components/Section"
import axios from "axios"
import CallToAction from "../components/CallToAction"
import PrimaryButton from "../components/Button/PrimaryButton"
import Products from "../sections/Product/Products"
import { createClient } from "contentful-management"

const SyncButton = styled.div`
  display: none;
  text-align: center;
  width: fit-content;
  margin: 2rem auto;

  &:hover {
    cursor: pointer;
  }
`

const SyncingStatusPanel = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
`

const SyncingStatusContent = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductPage = pageData => {
  const [seseiProductsData, setSenseiProductsData] = useState([])
  const [syncing, setSyncing] = useState("")
  const [syncingStatus, setSyncingStatus] = useState({})

  const productData = pageData.data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  );
  
  const getSeseiProducts = async () => {
    const res = await axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/v1/product/listing",
      data: {
        limit: 400,
      },
      headers: {
        Authorization:
          "Bearer " +
          "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
      },
    })
    setSenseiProductsData(res.data.data)
  }

  useEffect(() => {
    getSeseiProducts()
  }, [])

  const getProductImage = senseiElement => {
    const image = senseiElement.image_data.find(item => item.is_default === 1)
    if (!image) {
      return senseiElement.image_data[senseiElement.image_data.length - 1]
    }
    return image
  }

  const syncProducts = async () => {
    const senseiData = seseiProductsData.data
    const client = createClient({
      accessToken: "CFPAT-6mLLDRiqRmKYIHJSVBKjT3J-TLCtbXdJE9NaykR0cWA",
    })

    let st = {
      total: senseiData.length,
      updated: 0,
      created: 0,
      failed: 0,
    }
    setSyncingStatus(st)
    setSyncing("syncing")
    const space = await client.getSpace("z75g1qwq4el7")
    const environment = await space.getEnvironment("master")
    const productSkuMap = productData.reduce((result, item) => {
      return {
        ...result,
        [item.node.skuNumber]: item,
      }
    }, {})

    for (const element of senseiData) {
      const senseiWebSku = element.web_sku.slice(3)
      const senseiId = element.id
      const realImage = getProductImage(element)

      try {
        if (productSkuMap[senseiWebSku]) {
          const entry = await environment.getEntry(
            productSkuMap[senseiWebSku].node.contentful_id
          )

          entry.fields.title = { "en-US": element.name }
          entry.fields.skuNumber = { "en-US": Number(senseiWebSku) }
          entry.fields.senseiProductId = { "en-US": String(senseiId) }
          entry.fields.imageSrc = { "en-US": realImage.image_url }
          entry.fields.width = { "en-US": String(element.width) }
          entry.fields.height = { "en-US": String(element.height) }
          entry.fields.length = { "en-US": String(element.length) }
          entry.fields.price = { "en-US": Math.round(element.order_total) }
          entry.fields.gauge = { "en-US": Math.round(element.gauge) }
          const uEntry = await entry.update()
          const pEntry = await uEntry.publish()
          st.updated++
          setSyncingStatus({ ...st })
          console.log(`~~~ Entry ${pEntry.sys.id} published.`)
        } else {
          const entry = await environment.createEntry("product", {
            fields: {
              title: {
                "en-US": element.name,
              },
              skuNumber: {
                "en-US": Number(senseiWebSku),
              },
              senseiProductId: {
                "en-US": String(senseiId),
              },
              imageSrc: {
                "en-US": realImage.image_url,
              },
              width: {
                "en-US": String(element.width),
              },
              height: {
                "en-US": String(element.height),
              },
              length: {
                "en-US": String(element.length),
              },
              price: {
                "en-US": Math.round(element.order_total),
              },
              gauge: {
                "en-US": Math.round(element.gauge),
              },
            },
          })
          const pEntry = await entry.publish()
          st.created++
          setSyncingStatus({ ...st })
          console.log("~~~ publish:", pEntry.sys.id)
        }
      } catch (error) {
        st.failed++
        setSyncingStatus({ ...st })
      }
    }
    console.log("***** Finished ******")
    setSyncing("success")
  }

  return (
    <Layout location={pageData.location}>
      <SEO
        title="Shop Metal Buildings – Durable and Customizable Steel Structures for Every Need"
        description="Shop durable and customizable metal buildings from Probuilt Steel Buildings. Explore our range of high-quality steel structures for residential, commercial, and agricultural purposes."
      />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="160px"
        xpb="40px"
        pt="100px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <SectionPageTitle textAlign="center">
            Explore All Building Products
          </SectionPageTitle>
          <SectionDescription mb="0">
            <p>
              ProBuilt’s primary mission is to help our customers protect their
              automobiles, farm equipment, gardening accessories, and so much
              more by providing the highest quality metal buildings. ProBuilt
              Steel Buildings are designed to be highly functional, versatile,
              and meet superior engineering standards. They are the perfect
              addition to any home or business.
            </p>
          </SectionDescription>
            {/* <SyncButton onClick={() => syncProducts()}>
              <PrimaryButton text="Explore All Products" />
            </SyncButton> */}
          {!!seseiProductsData && !!seseiProductsData.data && (
            <SyncButton onClick={() => syncProducts()}>
              <PrimaryButton text="Explore All Products" />
            </SyncButton>
          )}
          {!!syncing && (
            <SyncingStatusPanel>
              <SyncingStatusContent>
                <span>
                  Total:{`  `}
                  <b>{syncingStatus.total}</b>
                </span>
                <span>
                  Updated: {`  `}
                  <b>{syncingStatus.updated}</b>
                </span>
                <span>
                  Created: {`  `}
                  <b>{syncingStatus.created}</b>
                </span>
                <span>
                  Failed: {`  `}
                  <b>{syncingStatus.failed}</b>
                </span>
              </SyncingStatusContent>
              {syncing === "success" && (
                <p>Syncing was finished successfully!</p>
              )}
            </SyncingStatusPanel>
          )}
        </Container>
      </SectionPage>
      {!!productData && productData.length && (
        <Products
          location={pageData.location}
          products={productData}
          productCategories={pageData.data.allContentfulProductCategory.edges}
          productRoofTypes={pageData.data.allContentfulProductRoofType.edges}
        />
      )}
      <CallToAction />
    </Layout>
  )
}

export default ProductPage

export const pageQuery = graphql`
  query ProductPageQuery {
    allContentfulProduct(sort: {fields: shopOrder, order: ASC}) {
      edges {
        node {
          id
          shopOrder
          senseiProductId
          contentful_id
          skuNumber
          shopOrder
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
          tags
        }
      }
    }
    allContentfulProductCategory(
      filter: { filterableCategory: { eq: true } }
      sort: { fields: order }
    ) {
      edges {
        node {
          name
        }
      }
    }
    allContentfulProductRoofType(filter: { filterableType: { eq: true } }) {
      edges {
        node {
          name
        }
      }
    }
  }
`