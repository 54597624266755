import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  SectionTitle,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  SectionPage,
  Container,
  BreadCrumb,
} from "../components/Section"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
} from "../components/Hero"
import ProductImageCarousel from "../components/ProductImageCarousel"
import ProductCardList from "../components/ProductCardList"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import generateHTML from "../utils/generateHTML"

class ProductOtherBarnsTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulProductCategoryLanding
    const currentData = this.props.data.allContentfulProduct.edges.filter(
      edge =>
        edge.node.productCategory &&
        edge.node.productCategory.name &&
        edge.node.productRoofType &&
        edge.node.productRoofType.name &&
        edge.node.productCategory.name === pageData.category.name
    )

    return (
      <Layout location={this.props.location}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />
        <HeroSection>
          <Container>
            <BreadCrumb className="static">
              <Link to="/">Home </Link> / <Link to="/barns/">Barns </Link> /{" "}
              <span>{pageData.title}</span>
            </BreadCrumb>
            <HeroCover>
              <LeftHero>
                <HeroPageTitle>{pageData.heroTitle}</HeroPageTitle>
                <HeroDesc
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </LeftHero>
              <RightHero>
                <ProductImageCarousel
                  product={currentData}
                  location={this.props.location}
                />
              </RightHero>
            </HeroCover>
          </Container>
        </HeroSection>
        <SectionPage
          ept="80px"
          epb="80px"
          xpt="60px"
          xpb="60px"
          pt="40px"
          pb="40px"
          bg="#F4FBFF"
        >
          <Container>
            <SectionTitle>{pageData.productListTitle}</SectionTitle>
            <ProductCardList
              data={currentData}
              location={this.props.location}
            />
          </Container>
        </SectionPage>
        <CallToAction />
        <SectionPage
          ept="80px"
          epb="80px"
          xpt="60px"
          xpb="60px"
          pt="40px"
          pb="40px"
          bg="#fff"
        >
          <Container>
            <LeftPannel>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(pageData.content.content),
                }}
              />
            </LeftPannel>
            <RightPannel>
              <DesignPlan />
              <AccreditedBusiness />
            </RightPannel>
          </Container>
        </SectionPage>
      </Layout>
    )
  }
}

export default ProductOtherBarnsTemplate

export const pageQuery = graphql`
  query ProductOtherBarnsTemplateQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
      }
      productListTitle
      content {
        content
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
        }
      }
    }
  }
`
