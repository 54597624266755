import styled from "styled-components"

export const DurableSectionContent = styled.div`
  margin-top: 3rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const DurableItem = styled.div`
  position: relative;
  padding-top: 80px;
  cursor: pointer;
`

export const DurableSteelBuildingImage = styled.div`
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  height: 160px;
  overflow: hidden;

  img {
    width: 100% !important;
    height: 160px !important;
    border-radius: 8px;
  }
`

export const DurableSteelBuildingData = styled.div`
  background: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  padding: 100px 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .durable-title {
    font-weight: 500;
    font-size: 20px;
    color: black;
  }
`

export const DetailButton = styled.button`
  padding: 3px 11px;
  background: hsl(15, 100%, 63.5%);
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 4px 4px rgba(255, 114, 69, 0.1);

  svg {
    width: 11px;
    height: 11px;
  }
`
