import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import PrimaryButton from "../Button/PrimaryButton"
import WhiteLinkButton from "../Button/WhiteLinkButton"
import MapPointer from "../Icons/MapPointer"
import {
  Container,
  SectionPage,
  SectionTitle,
  SectionDescription,
} from "../Section"
import Img from "gatsby-image"

const MapWrap = styled.div``

const MapGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 10px -10px;
  @media (min-width: 576px) {
    margin: 0 -15px 15px -15px;
  }
  @media (min-width: 768px) {
    margin: 0 -20px 20px -20px;
  }
  @media (min-width: 992px) {
    margin: 0 -20px 40px -20px;
  }
`
const LeftMapGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 576px) {
    padding: 0 15px;
  }
  @media (min-width: 768px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 0 20px;
  }
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`
const RightMapGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 576px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 0 15px;
  }
  @media (min-width: 768px) {
    padding: 0 20px;
  }
`
const ListState = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  @media (min-width: 576px) {
    margin: 0 -10px;
  }
  @media (min-width: 768px) {
    margin: 0 -15px;
  }
`
const ListItemState = styled.div`
  position: relative;
  width: 100%;
  padding: 5px;
  flex: 0 0 50%;
  max-width: 50%;
  .btn {
    font-weight: 400;
    @media (max-width: 575.98px) {
      font-size: 14px;
      line-height: 28px;
      padding: 3px 10px;
      justify-content: flex-start;
      .icon + .text {
        margin-left: 5px;
      }
    }
  }
  @media (min-width: 576px) {
    padding: 10px;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media (min-width: 1200px) {
    padding: 15px;
  }
  a {
    display: flex;
    .btn {
      display: flex;
      width: 100%;
    }
  }
`

const MapFlorida = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    border-right: 3px solid #062c44;
    justify-content: space-between;
  }
`
const MapLink = styled.div`
  margin: 0 0 15px;
  @media (min-width: 768px) {
    margin: 0;
  }
  .btn {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    &:after {
      display: inline-flex;
      content: "";
      position: absolute;
      left: 0;
      top: calc(100% - 1px);
      width: 100%;
      height: 1px;
      background-color: #fff;
      border: none;
    }
    &:hover {
      &:after {
        background-color: transparent;
      }
    }
  }
`
const MapThumb = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  img {
    width: 200px !important;
    height: auto !important;
  }
  @media (max-width: 767.98px) {
    display: none;
  }
`
const MapBkg = styled.div`
  .gatsby-image-wrapper {
    display: block;
  }
`

const Map = ({ stateName, mapImage, cities, jsonCities }) => {
  if (!stateName) return null
  if (!mapImage) return null

  return (
    <SectionPage
      ept="80px"
      epb="80px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
      bg="#1E4156"
    >
      <MapWrap>
        <Container maxWidth="1200px">
          <SectionTitle color="#fff">
            Cities We Serve In <strong>{stateName}</strong>
          </SectionTitle>
          <SectionDescription textAlign="center" color="#fff">
            <p>
              We cover all locations throughout the state of{" "}
              <strong>{stateName}</strong>, including:
            </p>
          </SectionDescription>
          <ListState>
            {!!cities &&
              cities.length > 0 &&
              cities.map((city, i) => (
                <ListItemState key={i}>
                  <Link to={`${city.url}/`}>
                    <PrimaryButton text={city.cityName} icon={<MapPointer />} />
                  </Link>
                </ListItemState>
              ))}
            {!!jsonCities &&
              jsonCities.length > 0 &&
              jsonCities.map((cc, j) => (
                <ListItemState key={j}>
                  <PrimaryButton text={cc.content} icon={<MapPointer />} />
                </ListItemState>
              ))}
          </ListState>
        </Container>
      </MapWrap>
    </SectionPage>
  )
}

export default Map
