import React, { Component } from "react"
import { Link } from "gatsby"
import {
  SectionPage,
  Container,
  SectionHeading,
  SectionDescription,
  ExploreMore,
} from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"
import ProductCardList from "../../components/ProductCardList"

class CardSection extends Component {
  render() {
    const { data, productData, location } = this.props
    let products = []
    productData.forEach(function(node, index) {
      if (index < 8) {
        products.push(node)
      }
    })
    return (
      <SectionPage
        ept="80px"
        epb="40px"
        xpt="60px"
        xpb="40px"
        pt="40px"
        pb="40px"
        bg="#F4FBFF"
      >
        <Container>
          <SectionHeading textAlign="center">{data.title}</SectionHeading>
          <SectionDescription>
            <p>{data.description.description}</p>
          </SectionDescription>
          <ProductCardList data={products} layout="home" location={location} />
          <ExploreMore>
            <Link to="/shop-metal-buildings/" className="btn-lg">
              <PrimaryButton text="Explore More Buildings" />
            </Link>
          </ExploreMore>
        </Container>
      </SectionPage>
    )
  }
}

export default CardSection
