import styled from 'styled-components'
import Check from "../../images/Check.png"

export const HeroSection = styled.section`
	background-color: ${props=>props.bg};
	position: relative;
	width: 100%;
	padding-top: 100px;
	padding-bottom: 5px;
	@media (min-width: 1200px) {	
		padding-top: 160px;
		padding-bottom: 20px;
	}
	@media (min-width: 1600px) {	
		padding-top: 160px;
		padding-bottom: 40px;
	}
`

HeroSection.defaultProps = {
	bg: "#fff",
}


export const HeroPageTitle = styled.h1`
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width: ${props=>props.maxWidth};
    width:100%;
`
HeroPageTitle.defaultProps = {	
	mb: "15px",
	color: "#062c44",
}

export const HeroDesc = styled.div`
	max-width: ${props=>props.maxWidth};
	width:100%;
	margin:0 auto ${props=>props.mb} auto;
	text-align: ${props=>props.textAlign};	
	color: #010920;
	font-family: Roboto;
	font-size: 16px;
	line-height: 26px;
	@media(min-width: 992px){
		font-size: 18px;
    line-height: 30px;
	}
  a{
    display: inline-block;
    color: #ea5f2c;
    &:hover{
      color:#000;
    }
  }
	& ul {
    list-style: none;
    margin: 0 0 0 20px;
    & li {
      vertical-align: text-top;
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      color: #1e4156;
      margin: 0 0 10px;
      padding: 0;
      &:before {
        content: url(${Check});
        display: inline-block;
        margin-left: -20px;
        width: 20px;
        vertical-align: top;
      }
    }
    + h2,
    + h3 {
      padding-top: 15px;
      @media (min-width: 992px) {
        padding-top: 30px;
      }
    }
  }

`
HeroDesc.defaultProps = {	
	mb: "30px",
}


export const HeroCover = styled.div`
	display:flex;
	flex-wrap:wrap;
	margin:0 -15px;
  text-align: ${props=>props.textAlign};	
`
HeroCover.defaultProps = {	
	textAlign: "left",
}
export const LeftHero = styled.div`
	position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width:992px){
		flex:0 0 50%;
		max-width:50%;
	}
`
export const RightHero = styled.div`
	position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width:992px){
		flex:0 0 50%;
		max-width:50%;
	}
`

export const HeroFormWrap = styled.div`
  position: relative;
  background: #fff;
  border: 1px solid #98dfff;
  box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
  border-radius: 10px;
  padding:20px 15px;
  @media (min-width: 768px) {
    padding:20px 30px;
  }
  @media (min-width: 992px) {
    padding:20px 30px 30px 30px;
  }  
`

export const HeroFormTitle = styled.div`
  font-family: Chakra Petch, sans-serif;
  font-weight: 700;
  margin: 0 0 20px;
  text-align:center;
  color: #062c44;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 992px) {    
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {    
    font-size: 28px;
    line-height: 38px;
  }
  @media (min-width: 1600px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const HeroFormBody = styled.div`
  margin:0;
  form{
    margin-bottom:0;
  }
`

export const HeroAction = styled.div`
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
  justify-content: ${props=>props.justifyContent};	
  margin:0 -5px 30px -5px;
`
export const HeroItemAction = styled.div`
  position:relative;
  padding:0 5px;
`