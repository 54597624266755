import React, { useState } from 'react'
import { Link, graphql } from "gatsby"
import  PlusIcon  from "../components/Icons/PlusIcon"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { SectionPage, Container, BreadCrumb, SectionPageTitle , MarkdownContent, SectionDescription } from "../components/Section"
import { DropWrapp, DropButton, DropButtonText, DropButtonImg, FadeContent} from "../components/FaqAccordion"

const FaqsPage = (pageData) =>{
	const [currentFaq, setCurrentFaq] = useState();
	const handleOpen = (i) => {
		if(currentFaq === i) {
			setCurrentFaq();
		} else {
			setCurrentFaq(i);
		}
	}

	return(
		<Layout location={pageData.location}>
			<SEO title="Faqs" description="Faqs" />
			<SectionPage
				ept="160px"
				epb="60px"
				xpt="160px"
				xpb="40px"
				pt="100px"
				pb="30px"
				bg="#fff"
			>
				<Container>
					<BreadCrumb className='static'><Link to="/">Home</Link> / <span>FAQ's </span></BreadCrumb>
					<SectionPageTitle textAlign="center">Frequently Asked Questions</SectionPageTitle>
					<SectionDescription><p>Here are several frequently asked questions customers have asked during the buying process.  If you have additional questions please feel free to speak to one of our building specialists.</p></SectionDescription>
					{
						pageData.data.allContentfulFaq.edges.map((item, i) => {
							return (
								<DropWrapp isVisible={currentFaq === i ? true : false}>
									<DropButton onClick={() => handleOpen(i)} isVisible={currentFaq === i ? true : false} >
										<DropButtonText>
											{item.node.question}
										</DropButtonText>
										<DropButtonImg isVisible={currentFaq === i ? true : false}>
											<PlusIcon fill="#1E4156" />
										</DropButtonImg>
									</DropButton>
									<FadeContent isVisible={currentFaq === i ? true : false}>
										<MarkdownContent dangerouslySetInnerHTML={{ __html: item.node.answer.childMarkdownRemark.html }} />
									</FadeContent>
								</DropWrapp>
							)
						})
					}
				</Container>
			</SectionPage>
			<CallToAction />
		</Layout>
	)
}

export default FaqsPage

export const pageQuery = graphql`
  query FaqsPageQuery {
	  allContentfulFaq {
	    edges {
	      node {
	        question
	        answer {
	          childMarkdownRemark {
	            html
	          }
	        }
	      }
	    }
	  }
	}
`