import { useStaticQuery, graphql } from "gatsby"
import { useEffect, useState } from "react"

export const useSearch = (keyword, type, isSearch) => {
  const [results, setResults] = useState([])
  const data = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        edges {
          node {
            id
            senseiProductId
            skuNumber
            image {
              fluid(maxWidth: 700, quality: 100) {
                base64
                sizes
                aspectRatio
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            imageSrc
            title
            description {
              description
            }
            width
            length
            height
            price
            priceDescription
            gauge
            productServices {
              spaceId
              service
              image {
                fluid(maxWidth: 45, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcWebp
                  srcSetWebp
                  srcSet
                }
              }
            }
            tags
            productRoofType {
              name
            }
            productCategory {
              name
            }
            numberOfSale
            canBeUsedAs {
              content
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!isSearch && !keyword) return

    const allProducts = data.allContentfulProduct.edges
      .filter(
        edge =>
          edge.node.productCategory &&
          edge.node.productCategory.name &&
          edge.node.productRoofType &&
          edge.node.productRoofType.name
      )
      .map(p => ({
        node: {
          ...p.node,
          productDesc: (p.node.description.description || "").replace(
            /<[^>]+>/g,
            ""
          ),
        },
      }))

    let tTags = []
    allProducts.forEach(p => {
      if (p.node.tags) {
        tTags = [...tTags, ...p.node.tags]
      }
    })

    const rs = (allProducts || [])
      .map(p => p.node)
      .filter(p => checkWithKeyword(p, keyword))

    if (type === "product") {
      setResults([...rs])
      return
    }

    const allTags = [...new Set(tTags.map(tag => tag.trim()))]
    const rt = (allTags || [])
      .filter(t => checkTagWithKeyword(t, keyword))
      .map(t => ({ title: t, type: "tag" }))

    setResults([...rs, ...rt])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])

  const checkWithKeyword = (product, search) => {
    if (product.title.toLowerCase().includes(search.toLowerCase())) return true
    return false
  }

  const checkTagWithKeyword = (tag, search) => {
    if (tag.toLowerCase().includes(search.toLowerCase())) return true
    return false
  }

  if (!isSearch && !keyword) return []

  return results
}
