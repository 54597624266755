import styled from "styled-components";

export const SidebarSticky = styled.div`
  overflow-x: hidden;
  margin: 0;
  background-color: #F4FBFF;
  border-radius:5px;
  border: 1px solid #abcadc;
  @media (max-width: 991.98px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    transition: all 0.5s;
    transform: translateY(100%);
    width: 100%;
    border:none;
    &.show {
      transform: translateY(0);
      .toggle {
        border-radius: 100%;
        svg {
          transform: scaleX(-1);
        }
      }
    }

    overflow-y: auto;
    height: 100%;
    ::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
export const SidebarStatic = styled.div`  
  margin: 0;
`;

export const SidebarHeaderSticky = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #062C44;
  position: relative;
  min-height: 50px;
  padding: 10px 15px;
  + .sidebar-body-sticky{
    padding-top:0;
    padding-bottom:0;
  }
`;
export const SidebarStickyAction = styled.div`
  display: flex;

`;

export const SidebarStickyReset = styled.button`
  @media (min-width: 992px) {
    display:none;
  }
  .btn{
    font-weight:400;
    color:#ed1c24;
    .text{
      font-size:12px !important;
    }
    &:hover{		
      color:#ed1c24;
    }
  }
`;
export const SidebarStickyClose = styled.button`
  background-color: transparent;
  border: transparent;
  display: flex;
  flex: wrap;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  @media (min-width: 992px) {
    display: none;
  }
  > svg {
    fill: #363636;
  }
`;
export const SidebarStickyTitle = styled.div`
  position: relative;
  display:flex;
  flex-wrap: nowrap;
  align-items:center;  
  text-align: left;  
  .icon, .text{
    display:flex; 
    align-items:center;
    flex-wrap: wrap;
  }
  .icon{
    + .text{
      margin-left:10px;
    }
  }
  .text{
    font-family: "Chakra Petch";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (min-width: 1600px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;
export const SidebarStickyBody = styled.div`
  overflow-x: hidden; 
 
  @media (min-width: 992px) {
    ::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    overflow-y: auto;
    height: calc(100vh - 170px);
  }
  @media (min-width: 1200px) {
    height: calc(100vh - 240px);
  }
  padding: 10px 15px;
`;
export const SidebarStaticBody = styled.div`
  padding: 10px 15px;
`;
export const SidbarStickyFooter = styled.div`
  height: 50px;
  padding: 10px 15px;
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color:#F4FBFF;
`;
export const SidebarStickyToolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
`;
export const SidebarItemToolbar = styled.div`
  position: relative;
  padding: 0 5px;
  button {
    padding: 0;
    margin: 0;
    display: flex;
  }
  .btn {
    text-transform: capitalize !important;
  }
`;

export const SidebarCard = styled.div`
  position: relative;
  text-align: center;
  background-color:#fff;
  border-radius:10px;
  margin-bottom:10px;
`;

export const SidebarCardHeader = styled.div`
  padding: 10px;
  @media (min-width: 1600px) {
    padding: 15px;
  }
  + .sidebar-body{
    padding-top:0;
  }
`;
export const SidebarCardTitle = styled.div`
  text-align: left;
  color: #062C44;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 10px;
  border-bottom:1px solid #ABCADC;
`;
export const SidebarCardBody = styled.div`
  padding: 10px;
  @media (min-width: 1600px) {
    padding: 15px;
  }
`;

export const ListFormGroup = styled.div`
  text-align:left;
`;
export const ListItemForm = styled.div`
  + .list-item{
    margin-top :5px;
  }
  label{
    font-family: "Chakra Petch";
    color:#062C44;
    font-weight: 600;
  }
`;

export const SearchForm = styled.div`
  .input-group,
  .form-group {
    .form-control {
      background: #fff;
      border: 1px solid #eee;
      padding: 5px 15px;
      font-size: 14px;
      line-height: 24px;
      border-radius:0;
      &:focus {
        border-color: #373636;
      }
    }
  }
  + .search-form {
    margin-top: 20px;
  }
`;
export const ListBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 30px -5px;
`;
export const BadgeTag = styled.label`
  color: #000;
  background-color: #fbe179;
  font-weight: 600;
  display: inline-block;
  border-radius: 30px;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  cursor: pointer;
  margin: 5px;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 8px;
  @media (min-width: 576px) {
    padding: 5px 15px;
  }
  @media (min-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
  &:hover {
    border-color: #ebc322;
    background-color: #ebc322;
    color: #000;
    background-color: #fbe179;
    font-weight: 600;
    display: inline-block;
    border-radius:30px;
    transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out;
    cursor: pointer;
    margin:5px;    
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
    @media (min-width: 576px) {
        padding: 5px 15px;
    }
    @media (min-width: 992px) {
        font-size: 16px;
        line-height: 20px;
    }
    &:hover{
        border-color: #ebc322;
        background-color: #ebc322;
        color: #000;
    }
  }
`

export  const Sidebar = styled.div`
`
export  const SidebarDefaultTitle = styled.div`
  margin-bottom: ${props=>props.mb};	
  font-weight:700;
  color:${props=>props.color};
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
    line-height: 40px;
  }
`
SidebarDefaultTitle.defaultProps = {
	color: "#373636",
	mb: "15px",
}


export  const SidebarDefault = styled.div`
  background-color:#fff;
  border-radius:15px;
  overflow:hidden;
  margin-bottom:30px;
  @media (min-width: 768px) {
    margin-bottom:40px;
  }
  `
export  const SidebarDefaultBody = styled.div`
  position:relative;
  background-color:#292929;
  overflow:hidden;
  padding:30px 20px;
  @media (min-width: 768px) {
    padding:30px;
  }
  @media (min-width: 1200px) {
    padding:30px 40px;
  }
  @media (min-width: 1600px) {
    padding:40px 50px;
  }
  + .phone-cta{
    margin-top:5px;
  }
`
export  const BuildingImg = styled.div`
  position: absolute;
  bottom: -7%;
  left: 0;
  right: 0;
  margin: 0 10px;
`;
export  const BuildingTexture = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.2;
`;