import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import SearchIcon from "../Icons/SearchIcon"
import { DropSearch, DropSearchBody, DropSearchItem } from "../Section"
import { getProductUrl } from "../../utils/product"
import { navigate } from "gatsby"

const SearchForm = styled.div`
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  position: relative;
  form {
    margin-bottom: 0;
  }
  .input-icon {
    > .form-control {
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid #dce7ef;
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }
    .icon {
      width: 45px;
      > svg {
        fill: #062c44;
        width: 20px;
      }
    }
  }
`

const SearchBox = ({ setQuery, searchKey, dropData }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const targetRef = useRef(null)
  const searchInputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleClickOutside = event => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      if (event.target.id !== searchInputRef.current.id) {
        setShowDropdown(false)
      }
    }
  }

  const handleChange = e => {
    e.preventDefault()
    setQuery(e.target.value)
  }
  const handleSubmit = e => {
    e.preventDefault()
  }

  const getSearchItemTitle = item => {
    if (searchKey === item.skuNumber.toString()) return item.skuNumber
    return item.title
  }

  return (
    <SearchForm className="search-form">
      <form onSubmit={e => handleSubmit(e)}>
        <div className="input-icon right">
          <input
            ref={searchInputRef}
            className="form-control"
            type="text"
            placeholder="Search..."
            id="search"
            name="search"
            autoComplete="off"
            onFocus={() => setShowDropdown(true)}
            onChange={e => {
              handleChange(e)
              setShowDropdown(true)
            }}
            value={searchKey}
            onKeyDown={event => {
              if (event.key === "Enter") {
                setShowDropdown(false)
              }
            }}
          />
          <span className="icon">
            <SearchIcon />
          </span>
        </div>
      </form>
      {showDropdown && (
        <DropSearch ref={targetRef} className="drop-search search-page">
          <DropSearchBody>
            {!!dropData &&
              dropData.map((item, i) => (
                <DropSearchItem
                  key={i}
                  onClick={() => {
                    const url = getProductUrl(item)
                    navigate(`${url}/`)
                  }}
                >
                  {getSearchItemTitle(item)}
                </DropSearchItem>
              ))}
          </DropSearchBody>
        </DropSearch>
      )}
    </SearchForm>
  )
}

export default SearchBox
