import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Nodata,
  NodataSubTitle,
  NodataDesc,
  NodataContainer,
} from "../components/Nodata"
import SearchBox from "../components/SearchBox"
import { useSearch } from "../utils/useSearch"
import SearchResult from "../components/SearchResult"
import { Container, SectionPage, SectionPageTitle } from "../components/Section"

const SearchList = styled.div`
  margin: 0;
`

const SearchForm = styled.div`
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto 30px auto;
  @media (min-width: 1200px) {
    margin: 0 auto 40px auto;
  }
  @media (min-width: 1600px) {
    margin: 0 auto 60px auto;
  }
  .input-icon {
    > .form-control {
      padding: 8px 15px;
      @media (min-width: 1200px) {
        padding: 10px 15px;
      }
      @media (min-width: 1600px) {
        padding: 15px;
      }
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }
    .icon {
      width: 45px;
      > svg {
        fill: #062c44;
        width: 20px;
      }
    }
    &.right {
      > .form-control {
        padding-right: 45px;
        padding-left: 15px;
      }
    }
  }
`

const SearchPage = ({ location }) => {
  const [query, setQuery] = useState(location?.state?.searchKey || "")
  const results = useSearch(query, "product", true)

  const renderSearchResult = () => {
    if (!results || results.length === 0) {
      return (
        <Nodata>
          <NodataContainer>
            <NodataSubTitle className="h3">No Results Found</NodataSubTitle>
            <NodataDesc>
              <p>
                The page you requested could not be found. Try refining your
                search, or use the navigation above to locate the post.
              </p>
            </NodataDesc>
          </NodataContainer>
        </Nodata>
      )
    }

    return <SearchResult items={results} />
  }

  return (
    <Layout hideSearch location={location}>
      <SEO title="Search" />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="160px"
        xpb="40px"
        pt="100px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <SectionPageTitle textAlign="center">Search Result</SectionPageTitle>
          <SearchForm className="search-form">
            <SearchBox
              setQuery={setQuery}
              searchKey={query}
              dropData={results}
            />
          </SearchForm>
          <SearchList className="search-list">
            {renderSearchResult()}
          </SearchList>
        </Container>
      </SectionPage>
    </Layout>
  )
}

export default SearchPage
